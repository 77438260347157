import {
  OrbitControls,
  OrthographicCamera,
  Stars,
  Text3D,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import styled from "styled-components";
import Earth from "./Earth";

// import NavBar from "./Navbar";

const Container = styled.div`
  background-color: white;
  height: 100vh;
  display: flex;

  @media only screen and (max-width: 768px) {
    align-items: center;
    height: 100vh;
    width: 100%;
  }
`;

export default function Hero() {
  return (
    <Container>
      <Canvas>
        <Stars
          radius={350}
          depth={50}
          count={6000}
          saturation={0}
          fade
          speed={1}
        />
        <OrthographicCamera
          makeDefault={true}
          far={100000}
          near={-100000}
          position={[0, 0, 1000]}
          scale={1}
        />
        <OrbitControls enableZoom={false} autoRotate />
        <Text3D
          font="../fonts/Darumadrop One_Regular.json"
          size={37}
          height={40}>
          dennis king,
          <meshNormalMaterial />
        </Text3D>
        <Text3D
          font="../fonts/Darumadrop One_Regular.json"
          size={37}
          height={40}
          position={[0, -40, 0]}>
          software engineer.
          <meshNormalMaterial />
        </Text3D>
        <Earth />
      </Canvas>
    </Container>
  );
}

// function text3D() {
//   return (
//     <mesh>
//       <Text3D font="../fonts/Darumadrop One_Regular.json" size={0.3}>
//         dennis king, software engineer.
//         <meshNormalMaterial />
//       </Text3D>
//     </mesh>
//   );
// }
