/*
  Auto-generated by Spline
*/

import useSpline from "@splinetool/r3f-spline";
import { OrthographicCamera } from "@react-three/drei";

export default function Scene({ ...props }) {
  const { nodes, materials } = useSpline(
    "https://prod.spline.design/eYCvWVpalXkS89yg/scene.splinecode"
  );
  return (
    <>
      <color attach="background" args={["#000000"]} />
      <group {...props} dispose={null}>
        <mesh
          name="Sphere"
          geometry={nodes.Sphere.geometry}
          material={materials["Sphere Material"]}
          castShadow
          receiveShadow
          position={[-250, 0, -350]}
          rotation={[0, -51, 0]}
          scale={1}
        />
        <directionalLight
          name="Directional Light"
          castShadow
          intensity={1}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-near={-10000}
          shadow-camera-far={100000}
          shadow-camera-left={-1250}
          shadow-camera-right={1250}
          shadow-camera-top={1250}
          shadow-camera-bottom={-1250}
          position={[100, 100, 100]}
        />
        <OrthographicCamera name="1" makeDefault={false} />
      </group>
    </>
  );
}
