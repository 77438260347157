import styled from "styled-components";
import LandingPage from "./components/LandingPage";

const Container = styled.div`
  scroll-behavior: smooth;
  scrollbar-width: none;
  height: 100vh;
  background-color: black;
  &::-webkit-scrollbar {
    display: none;
  }
`;

function App() {
  return (
    <Container className="App">
      <LandingPage />
    </Container>
  );
}

export default App;
